<template>
  <section class="box-height accrualReport">
    <el-form
      :model="filter"
      ref="filter"
      style="background-color: #fff; height: 50px; padding-top: 15px"
      label-width="120px"
    >
      <el-form-item prop="ids" label="团队：" label-width="50px" class="fl">
        <el-cascader
          class="searchCascader"
          style="min-width: 240px; height: 35px"
          filterable
          clearable
          :options="options2"
          collapse-tags
          :show-all-levels="false"
          v-model="ids"
          :props="props"
          placeholder="请选择小类"
          ref="dataRef"
          @change="getSearch"
          :filter-method="filterFun"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item prop="date" label="计提所属月份：" class="fl">
        <el-date-picker
          v-model="filter.date"
          value-format="yyyy-MM-dd"
          @change="dateChage"
          type="monthrange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="min-width: 240px; height: 35px"
        ></el-date-picker>
        <el-radio-group
          v-model="filter.dateType"
          size="small"
          @change="dateTypeChage"
          style="margin: -2px 0 0 10px"
        >
          <el-radio-button label="THIS_YEAR">今年</el-radio-button
          ><el-radio-button label="LAST_YEAR">去年</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <el-row
      :span="24"
      class="body"
      style="margin-left: 0; margin-right: 0; display: flex; width: 100%;margin-bottom: 10px;"
      :gutter="10"
    >
      <el-col :span="6" style="padding-left:0">
        <div class="content">
          <p class="title">计提奖金总额（元）</p>
          <p class="number">{{ allMoney }}</p>
        </div>
      </el-col>
      <el-col :span="18" style="padding-right:0">
        <div class="content">
          <p class="title">计提奖金月总额（元）</p>
          <div
            class="bar botchart"
            :style="{ width: '100%', margin: '0 auto' }"
            ref="allMoney"
            id="allMoney"
          ></div>
        </div>
      </el-col>
    </el-row>
    <el-row
      :span="24"
      class="body"
      style="margin-left: 0; margin-right: 0"
      :gutter="20"
    >
      <p class="title" style="margin-left: 0;">
        团队计提明细
        <span class="tabHref" @click="exportTable('team')">
          <i class="el-icon-download"></i> 导出</span
        >
      </p>
      <table
        class="el-table tabBorder el-table--fit el-table--enable-row-hover"
        cellpadding="0"
        style="border-top: 0; background-color: #f5f7fa;border-left: 1px #ececec solid;border-right: 1px #ececec solid;"
        cellspacing="0"
      >
        <thead class="has-gutter" ref="has-gutter">
          <tr class="tableTdTrH">
            <th
              v-for="(tableItem, itemIndex) of teamData.header"
              :key="itemIndex"
              :style="teamData.header.length<12?`width: ${screenWidth / (teamData.header.length )}px`:'width:150px'"
              class="col"
            >
              {{ tableItem }}
            </th>
          </tr>
        </thead>
        <tbody v-if="teamData.data && teamData.data.length > 0">
          <tr
            class="tableTyTrH"
            v-for="(tableItem, itemIndex) of teamData.data"
            :key="itemIndex"
          >
            <td v-for="(item, index) of tableItem" :key="index"  :style="teamData.header.length<12?`width: ${screenWidth / (teamData.header.length )}px`:'width:150px'">
              <span>{{ item }} </span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <p
            style="
              text-align: center;
              height: 50px;
              line-height: 50px;
              color: #999;
            "
          >
            暂无数据
          </p>
        </tbody>
      </table>
    </el-row>
    <!-- <el-divider></el-divider> -->
    <el-row
      :span="24"
      class="body"
      style="margin-left: 0; margin-right: 0"
      :gutter="20"
    >
      <p class="title" style="margin-left: 0;">
        计提明细
        <span class="tabHref" @click="exportTable()">
          <i class="el-icon-download"></i>导出</span
        >
      </p>
      <el-table
        :header-cell-style="{
          'background-color': '#f5f7fa',
        }"
        border
        :span-method="objectSpanMethod"
        :data="detailsData"
        class="tabBorder custor is-top"
      >
        <el-table-column prop="userName" fixed min-width="180">
          <template slot="header">
            <span class="header-label">顾问</span>
          </template>
          <template slot-scope="scope">
            <div style="display: flex">
              <p :title="scope.row.userName">
                {{ scope.row.userName }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="workId" width="80" label="工号">
          <template slot-scope="scope">
            <span>{{ scope.row.workId }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="departmentName" width="120" label="所属团队">
        </el-table-column>
        <el-table-column width="140">
          <template slot="header">
            <span class="header-label">总计提奖金</span>
            <el-tooltip class="item" effect="dark" placement="bottom" content="累计应发总奖金 - 累计应发已计提">
              <i
                class="iconfont icon icon-wenhao-xiangsu"
                style="color: #898989; font-size: 14px; font-weight: 400"
              ></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <div style="display: flex">
              <span style="color: orange">{{ scope.row.totalBonus }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="180">
          <template slot="header">
            <span class="header-label" style="font-size: 12px"
              >累计应发已计提
            </span>
            <el-tooltip class="item" effect="dark" placement="bottom">
              <template slot="content">
                指测算年度内已计提和待计提的奖金总额。<br />
                待确认计提表的奖金已被锁定额度。</template
              >
              <i
                class="iconfont icon icon-wenhao-xiangsu"
                style="color: #898989; font-size: 14px; font-weight: 400"
              ></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <p
              class="tabHref"
              v-if="scope.row.accruedPaidBonus !== null"
              @click="showPerformanceDram('accruedPaidBonus', scope.row)"
            >
              {{ scope.row.accruedPaidBonus }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column width="150" label="所属年份">
          <template slot-scope="scope">
            <span>
              {{ scope.row.yearValue ? scope.row.yearValue : "-" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <span class="header-label">开票时间 </span>
            <el-tooltip
              class="item"
              effect="dark"
              content="指测算时“开票时间”查询条件值"
              placement="bottom"
            >
              <i
                class="iconfont icon icon-wenhao-xiangsu"
                style="color: #898989; font-size: 14px; font-weight: 400"
              ></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <p v-if="scope.row.recordInvoiceStartDate">
              {{
                scope.row.recordInvoiceStartDate
                  ? scope.row.recordInvoiceStartDate
                  : "-"
              }}至{{
                scope.row.recordInvoiceEndDate
                  ? scope.row.recordInvoiceEndDate
                  : "-"
              }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column width="150">
          <template slot="header">
            <span class="header-label">职级 </span>
          </template>
          <template slot-scope="scope">
            <p>
              {{
                scope.row.calculateModelName
                  ? scope.row.calculateModelName
                  : "-"
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot="header">
            <span class="header-label">入职时间 </span>
          </template>
          <template slot-scope="scope">
            <p>
              {{ scope.row.entryDate ? scope.row.entryDate : "-" }}
            </p>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot="header">
            <span class="header-label">离职时间 </span>
          </template>
          <template slot-scope="scope">
            <p>
              {{ scope.row.leaveDate ? scope.row.leaveDate : "-" }}
            </p>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <span class="header-label">开票周期 </span>
            <el-tooltip
              class="item"
              effect="dark"
              content="指对应职级的开票周期。根据查询的“开票时间”自动拆分。"
              placement="bottom"
            >
              <i
                class="iconfont icon icon-wenhao-xiangsu"
                style="color: #898989; font-size: 14px; font-weight: 400"
              ></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <p v-if="scope.row.invoiceStartDate">
              {{
                scope.row.invoiceStartDate ? scope.row.invoiceStartDate : "-"
              }}至{{
                scope.row.invoiceEndDate ? scope.row.invoiceEndDate : "-"
              }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column width="100" label="开票业绩">
          <template slot-scope="scope">
            <p
              class="tabHref"
              v-if="scope.row.invoiceMoney"
              @click="showPerformanceDram('Invoicing', scope.row)"
            >
              {{ scope.row.invoiceMoney }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column width="140">
          <template slot="header">
            <span class="header-label">累计应发总奖金 </span>
            <el-tooltip class="item" effect="dark" placement="bottom">
              <template slot="content">
                计算公式：累计应发总奖金=开票业绩 * 奖金比例 - 累计固定工资
                <br />计算规则：按开票时间找对应的奖金计算规则。按开票业绩计算奖金。
              </template>
              <i
                class="iconfont icon icon-wenhao-xiangsu"
                style="color: #898989; font-size: 14px; font-weight: 400"
              ></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <p
              class="tabHref"
              v-if="scope.row.accruedBonus !== null"
              @click="showPerformanceDram('accruedBonus', scope.row)"
            >
              {{ scope.row.accruedBonus }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>

        <el-table-column width="180">
          <template slot="header">
            <span class="header-label" style="font-size: 12px"
              >累计固定工资
            </span>
            <el-tooltip class="item" effect="dark" placement="bottom">
              <template slot="content">
                特别说明：<br />
                寻访员奖金与固定工资无关，默认按0处理</template
              >
              <i
                class="iconfont icon icon-wenhao-xiangsu"
                style="color: #898989; font-size: 14px; font-weight: 400"
              ></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <p
              class="tabHref"
              v-if="scope.row.accruedSalary !== null"
              @click="showPerformanceDram('accruedSalary', scope.row)"
            >
              {{ scope.row.accruedSalary }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-drawer
      :visible.sync="drawerVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      :modal="false"
      direction="ttb"
      custom-class="drawerClass"
      :before-close="handleDrawerClose"
    >
      <div slot="title" class="drawerTitle">
        {{ drawerName }}
      </div>
      <el-row v-if="drawerVisible">
        <el-col
          style="
            padding: 10px 15px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <el-button
            v-if="drawType !== 'accruedBonus'"
            size="mini"
            @click="downInfoExp"
            :loading="btnLoading"
            >导出</el-button
          >
          <span v-if="drawType == 'accruedSalary'"
            >累计固定工资总额：
            <span style="color: orange; font-weight: 600"
              >{{ totalSalary }}
            </span>
            元</span
          >
          <el-pagination
            :hide-on-single-page="page.total > 10 ? false : true"
            v-if="page.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.current"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="page.size"
            layout="total,prev, pager, next,sizes"
            :total="page.total"
            style="padding-right: 0px"
          ></el-pagination>
        </el-col>
        <el-col>
          <el-table
            v-if="drawType == 'Invoicing' || drawType == 'Payback'"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="InfoData"
            class="tabBorder custor is-top"
          >
            <el-table-column
              v-if="drawType == 'Payback'"
              label="回款时间"
              width="150"
              prop=""
            >
              <template slot-scope="scope">
                {{
                  scope.row.lastModifiedTime
                    ? scope.row.lastModifiedTime.substring(0, 10)
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column
              v-if="drawType == 'Payback'"
              label="开票时间"
              width="150"
              prop=""
            >
              <template slot-scope="scope">
                {{
                  scope.row.actualMakeInvoiceDate
                    ? scope.row.actualMakeInvoiceDate.substring(0, 10)
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column v-else label="时间" width="150" prop="">
              <template slot-scope="scope">
                {{
                  scope.row.lastModifiedTime
                    ? scope.row.lastModifiedTime.substring(0, 10)
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="总业绩"></el-table-column>
            <el-table-column
              prop="achievement"
              width="150"
              label="实际业绩"
            ></el-table-column>
            <el-table-column
              label="业绩创造者"
              prop="nickName"
              width="150"
            ></el-table-column>
            <el-table-column
              label="业绩类型"
              width="150"
              prop="roleName"
            ></el-table-column>
            <el-table-column
              label="分配比例（个人）"
              width="160"
              prop="allocatePercentStr"
            >
              <template slot-scope="scope">
                {{
                  scope.row.allocatePercentStr
                    ? scope.row.allocatePercentStr
                    : "-"
                }}
              </template>
            </el-table-column>
            <el-table-column label="项目名称" show-overflow-tooltip>
              <template #default="scope">
                <router-link
                  class="tabHref"
                  target="_blank"
                  :to="{
                    path: '/project/details',
                    query: { id: scope.row.projectId },
                  }"
                  >{{ scope.row.projectName }}
                </router-link>
              </template></el-table-column
            >
            <el-table-column label="候选人" width="120">
              <template #default="scope">
                <span class="tabHref" @click="showDrawer(scope.row.resumeId)">{{
                  scope.row.resumeName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="industryName" label="行业">
              <template #default="scope">
                {{ scope.row.industryName ? scope.row.industryName : "-" }}
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-if="drawType == 'accruedBonus'"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="InfoData"
            class="tabBorder custor is-top"
          >
            <el-table-column
              prop="userName"
              label="顾问"
              min-width="180"
            ></el-table-column>
            <el-table-column label="开票周期" min-width="180">
              <template slot-scope="scope">
                <p v-if="scope.row.invoiceStartDate">
                  {{
                    scope.row.invoiceStartDate
                      ? scope.row.invoiceStartDate
                      : "-"
                  }}至{{
                    scope.row.invoiceEndDate ? scope.row.invoiceEndDate : "-"
                  }}
                </p>
                <p v-else>-</p>
              </template>
            </el-table-column>
            <el-table-column
              prop="calculateModelName"
              label="职级"
              min-width="120"
            ></el-table-column>
            <el-table-column
              prop="invoiceMoney"
              label="开票业绩"
            ></el-table-column>
            <el-table-column label="*" width="40">
              <template slot-scope="scope">
                <p>*</p>
              </template>
            </el-table-column>
            <el-table-column
              prop="baseRatio"
              label="比例"
              width="80"
            ></el-table-column>
            <el-table-column label="-" width="40">
              <template slot-scope="scope">
                <p>-</p>
              </template>
            </el-table-column>
            <el-table-column prop="accruedSalary" min-width="180">
              <template slot="header">
                <span class="header-label">累计固定工资 </span>
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <template slot="content">
                    特别说明：<br />
                    寻访员奖金与固定工资无关，默认按0处理</template
                  >
                  <i
                    class="iconfont icon icon-wenhao-xiangsu"
                    style="color: #898989; font-size: 14px; font-weight: 400"
                  ></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <p v-if="scope.row.accruedSalary">
                  {{ scope.row.accruedSalary }}
                </p>
                <p v-else>-</p>
              </template>
            </el-table-column>
            <el-table-column label="=" width="40">
              <template slot-scope="scope">
                <p>=</p>
              </template>
            </el-table-column>
            <el-table-column prop="accruedBonus" width="180">
              <template slot="header">
                <span class="header-label" style="color: #e60012"
                  >累计应发总奖金
                </span>
              </template>
              <template slot-scope="scope">
                <p style="color: #e60012">
                  {{ scope.row.accruedBonus }}
                </p>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-if="drawType == 'accruedPaidBonus'"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="InfoData"
            class="tabBorder custor is-top"
          >
            <el-table-column
              prop="bonusTableName"
              label="计提表名称"
            ></el-table-column>
            <el-table-column
              prop="workId"
              label="工号"
            ></el-table-column>
            <el-table-column
              prop="yearValueStr"
              label="计提奖金所属年份"
            ></el-table-column>
            <el-table-column
              prop="paidBonus"
              label="计提金额（元）"
            ></el-table-column>
            <el-table-column prop="payStatus" label="确认状态">
              <template slot-scope="scope">
                <p
                  :style="
                    scope.row.payStatus == '待发放' ? 'color: #526ecc' : ''
                  "
                >
                  {{ scope.row.payStatus }}
                </p>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-if="drawType == 'accruedSalary'"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="InfoData"
            class="tabBorder custor is-top"
          >
            <el-table-column prop="monthValue" label="月份"></el-table-column>
            <el-table-column prop="workTime" label="在职时长"></el-table-column>
            <el-table-column prop="salary" label="固定工资金额">
              <template slot-scope="scope">
                <span style="color: orange">
                  {{ scope.row.salary }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-drawer>
    <el-drawer
      :visible.sync="drawerResumeVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleDrawerClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kh_xq"></i>
        人才
      </div>
      <div class="drawerCon">
        <resumeDetails
          v-if="drawerResumeVisible"
          :resumeDetailId="resumeId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
      </div>
    </el-drawer>
  </section>
</template>

<script>
import {
  permissionTree, //获取团队
  accrualReportDetail, //计提明细
  accrualReportRecords, //计提奖金月总额
  accrualReportTotal, //计提奖金总额
  bonusRecordItemAccruedBonusList, //累计已发奖金列表
  bonusRecordItemExportAccruedBonus, //导出累计已发奖金列表
  bonusSalaryRecordList, //工资记录列表
  bonusSalaryRecordExport, //导出工资记录列表
  bonusRecordItemAccruedBonusInfo, //累计总奖金详情
  bonusAchievementDetailAccount, //回款业绩详情
  bonusAchievementDetailExportAccount, //导出回款业绩
  bonusAchievementDetailInvoice, //开票业绩详情
  bonusAchievementDetailExportInvoice, //导出开票业绩
  accrualReportTeamDetail, //团队计提明细
  accrualReportExportTeamDetail, //导出团队计提明细
  accrualReportExportDetail, //导出计提明细
} from "../../api/api";
import * as echarts from "echarts";
import { ApiBaseUrl } from "@/api/http";
import resumeDetails from "../../components/resumeDetail";
export default {
  components: {
    resumeDetails,
  },
  data() {
    return {
      baseUrl: ApiBaseUrl,
      filter: {
        startDate: "",
        endDate: "",
        date: [],
        dateType: "THIS_YEAR",
        ids: [],
      },
      teamData: [],
      InfoData: [],
      detailsData: [],
      searchIdList: [],
      options2: [],
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
        // checkStrictly: true
      },
      ids: [],
      allMoney: 0,
      chartData: {
        name: [],
        data: [],
      },
      page: {
        current: 1,
        size: 50,
        total: 0,
      },
      drawerVisible: false,
      drawerName: "",
      btnLoading: false,
      totalSalary: 0,
      drawerResumeVisible: false,
      resumeId: null,
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      repeatResume: { type: "列表", valId: "" },
      screenWidth:null
    };
  },
  created() {
    this.permissionTree(); //团队
    this.loadInfo();
    this.screenWidth=window.screen.width
    console.log(this.screenWidth)
  },
mounted(){
  
},
  methods: {
    //获取计提奖金月总额
    getAccrualReportRecords() {
      accrualReportRecords(this.filter).then((res) => {
        if (res.success) {
          let list = {
            name: [],
            data: [],
          };
          res.result.forEach((element) => {
            list.name.push(element.label);
            list.data.push(element.value);
          });

          this.chartData = list;
          this.drawBarChart();
        }
      });
    },
    //获取计提奖金总额
    getAccrualReportTotal() {
      accrualReportTotal(this.filter).then((res) => {
        if (res.success) {
          this.allMoney = res.result;
        }
      });
    },
    // 小类
    permissionTree(isfirst) {
      permissionTree({ type: "团队" }).then((res) => {
        if (res.success) {
          this.options2 = res.result;
        }
      });
    },
    getSearch(val) {
      const _nodeId = this.$refs.dataRef.getCheckedNodes(true);
      // let isChecked;
      this.filter.ids = this.ids.map((i) => {
        return i[i.length - 1];
      });
      this.loadInfo();
    },
    filterFun(data, value) {
      // 弹窗 - 拼音过滤
      return data.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    loadInfo() {
      this.filter.startDate = this.filter.date[0] ? this.filter.date[0] : "";
      this.filter.endDate = this.filter.date[1] ? this.filter.date[1] : "";
      accrualReportDetail(this.filter).then((res) => {
        if (res.success) {
          this.detailsData = res.result;
          let pos = 0;
          this.spanArr = [];
          for (var i = 0; i < this.detailsData.length; i++) {
            if (i === 0) {
              this.spanArr.push(1);
              pos = 0;
            } else {
              // 判断当前元素与上一个元素是否相同,因合并第一个所以[0]
              if (this.detailsData[i].id === this.detailsData[i - 1].id) {
                this.spanArr[pos] += 1;
                this.spanArr.push(0);
              } else {
                this.spanArr.push(1);
                pos = i;
              }
            }
          }
        }
      });
      this.getAccrualReportRecords(); //获取计提奖金月总额
      this.getAccrualReportTotal(); //获取计提奖金总额
      this.getAccrualReportTeamDetail(); //获取团队计提明细
    },
    getAccrualReportTeamDetail() {
      accrualReportTeamDetail(this.filter).then((res) => {
        if (res.success) {
          this.teamData = res.result;
        }
      });
    },
    //推进时长
    drawBarChart() {
      var chartDom = document.getElementById("allMoney");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        // title: {
        //   text: "推进时长",
        //   subtext: "（所有候选人各阶段的平均推进时长）",
        //   textStyle: {
        //     fontSize: 16,
        //     color: "#333",
        //   },
        //   left: "0",
        //   top: "0",
        // },
        legend: {
          top: "10px",
          itemHeight: 10,
          pageIconSize: 10,
          itemWidth: 20,
          textStyle: {
            color: "#606266",
            lineHeight: 18,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          //   formatter: function (params) {
          //     let str = params[0].name;
          //     params.forEach((item, index) => {
          //       str =
          //         str +
          //         "<br/>" +
          //         item.marker +
          //         item.seriesName +
          //         " : " +
          //         item.value +
          //         "天";
          //     });
          //     return str;
          //   },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
        },
        xAxis: {
          type: "category",
          data: this.chartData.name,
        },
        series: [
          {
            name: "计提奖金月总额",
            type: "bar",
            data: this.chartData.data,
          },
        ],
      };
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
    },
    //显示业绩弹窗
    showPerformanceDram(type, data) {
      this.drawType = type;
      this.DrawData = data;
      this.getInfoList();
      this.drawerVisible = true;
    },
    getInfoList() {
      let req = null;
      if (this.drawType == "Invoicing") {
        this.drawerName =
          "开票业绩--" +
          this.DrawData.userName +
          "（" +
          this.DrawData.invoiceStartDate +
          "至" +
          this.DrawData.invoiceEndDate +
          "）";
        bonusAchievementDetailInvoice({
          itemId: this.DrawData.itemId,
          page: this.page,
        }).then((res) => {
          if (res.success) {
            this.InfoData = res.result.records;
            this.page.total = res.result.total;
          }
        });
      } else if (this.drawType == "Payback") {
        this.drawerName =
          "回款业绩--" +
          this.DrawData.userName +
          "（" +
          this.DrawData.invoiceStartDate +
          "至" +
          this.DrawData.invoiceEndDate +
          "）";
        bonusAchievementDetailAccount({
          itemId: this.DrawData.itemId,
          page: this.page,
        }).then((res) => {
          if (res.success) {
            this.InfoData = res.result.records;
            this.page.total = res.result.total;
          }
        });
      } else if (this.drawType == "accruedBonus") {
        this.drawerName = "累计应发总奖金";
        bonusRecordItemAccruedBonusInfo({ itemId: this.DrawData.itemId }).then(
          (res) => {
            if (res.success) {
              this.InfoData = [res.result];
            }
          }
        );
      } else if (this.drawType == "accruedPaidBonus") {
        this.drawerName =
          "累计应发已计提--" +
          this.DrawData.userName +
          "（" +
          this.DrawData.invoiceStartDate +
          "至" +
          this.DrawData.invoiceEndDate +
          "）";
        bonusRecordItemAccruedBonusList({ id: this.DrawData.id }).then(
          (res) => {
            if (res.success) {
              this.InfoData = res.result;
            }
          }
        );
      } else if (this.drawType == "accruedSalary") {
        this.drawerName =
          "累计固定工资--" +
          this.DrawData.userName +
          "（" +
          this.DrawData.invoiceStartDate +
          "至" +
          this.DrawData.invoiceEndDate +
          "）";
        this.getBonusSalaryRecordList();
      }
    },
    //获取
    getBonusSalaryRecordList() {
      bonusSalaryRecordList({ itemId: this.DrawData.itemId }).then((res) => {
        if (res.success) {
          this.InfoData = res.result.salaryRecords;
          this.totalSalary = res.result.totalSalary;
        }
      });
    },
    //导出
    downInfoExp() {
      this.btnLoading = true;
      let req = null;
      if (this.drawType == "Invoicing") {
        req = bonusAchievementDetailExportInvoice({
          itemId: this.DrawData.itemId,
          page: this.page,
        });
      } else if (this.drawType == "Payback") {
        req = bonusAchievementDetailExportAccount({
          itemId: this.DrawData.itemId,
          page: this.page,
        });
      } else if (this.drawType == "accruedPaidBonus") {
        req = bonusRecordItemExportAccruedBonus({
          id: this.DrawData.id,
        });
      } else if (this.drawType == "accruedSalary") {
        req = bonusSalaryRecordExport({ itemId: this.DrawData.itemId });
      }
      req.then((res) => {
        if (res.success) {
          location.href = this.baseUrl + res.result;
        }
        this.btnLoading = false;
      });
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.getInfoList();
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.page.size = val;
      this.page.current = 1;
      this.getInfoList();
    },
    handleDrawerClose() {
      this.drawerVisible = false;
      this.drawerResumeVisible = false;
      this.page = { current: 1, size: 50, total: 0 };
    },
    exportTable(type) {
      let req = null;
      if (type == "team") {
        req = accrualReportExportTeamDetail(this.filter);
      } else {
        req = accrualReportExportDetail(this.filter);
      }
      req.then((res) => {
        if (res.success) {
          location.href = this.baseUrl + res.result;
        }
      });
    },
    dateChage(val) {
      if (val.length > 0) {
        this.filter.dateType = "";
      } else {
        this.filter.dateType = "THIS_YEAR";
      }
      this.loadInfo();
    },
    dateTypeChage(val) {
      this.filter.date = [];
      this.loadInfo();
    },
    //弹出抽屉
    showDrawer(id) {
      this.drawerResumeVisible = true;
      this.resumeId = id;
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex <= 6) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.tableTdTrH {
  line-height: 36px;
  height: 36px;
  font-weight: bold;
  background: #f5f7fa;
  .col{
    font-weight: bold;
                border-bottom: 1px solid rgb(229, 229, 229);
                border-top: 1px solid rgb(229, 229, 229);
                box-shadow: none;
                height: 39px;
  }
}
.tableTyTrH {
  height: auto;
  td:first-child,
  th:first-child,
  td:nth-child(2),
  th:nth-child(2) {
    background-color: #fff;
  }
}
.fixedLeft {
  td:first-child,
  th:first-child,
  td:nth-child(2),
  th:nth-child(2) {
    position: sticky;
    left: 0; /* 首行永远固定在左侧 */
    z-index: 4;
  }
}
.hasShadow {
  td:nth-child(2),
  th:nth-child(2) {
    left: 120px;
    box-shadow: 5px 0 10px -5px #ddd;
  }
}
.is_fixed {
  position: fixed;
  top: 44px;
  z-index: 9;
}
.kpiSet {
  height: 28px;
  /*padding: 10px 15px;*/
  border: 1px solid #ededed;
  width: 85px;
  line-height: 28px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  box-sizing: border-box;
  /* margin-left: 15px; */
  display: block;
  float: left;
  color: #606266;
  i {
    /*color: rgb(187, 187, 187);*/
    font-size: 14px;
  }

  &:hover {
    color: #333333;
    border: 1px solid #999999 !important;
  }
}

pre {
  font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif;
  white-space: pre-line;
}
table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
thead {
  // border-top: 1px solid #ebeef5;
  // border-bottom: 1px solid #ebeef5;
}
thead,
tbody {
  display: block;
}

th {
  padding: 0;
  /*flex: 1;*/
  background-color: rgb(245, 247, 250);
  font-weight: normal;
}

tr {
  height: 50px;
  line-height: 50px;
  /*display: flex;*/
}

td {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  white-space: nowrap;
}

td,
th {
  display: inline-block;
  width: 150px;
  /*flex: 1;*/
  padding: 0 15px;
}

.accrualReport {
  padding: 0 15px;
  margin: 0 15px;
  background: #fff;
  .body {
    // padding:0 15px;
  }
  .bar {
    height: 280px;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 1366px) {
      height: 300px;
    }
  }
  .botchart {
    height: 260px;
    // @media (max-width: 1366px) {
    //   height: 220px;
    // }
  }
  .content {
    border: 1px #e5e5e5 solid;
    border-radius: 4px;
    &:hover {
      -webkit-box-shadow: 0 3px 8px 1px #e0e0e0;
      box-shadow: 0 3px 8px 1px #e0e0e0;
    }
  }
  .title {
    font-weight: 600;
    margin: 10px 15px;
    .tabHref {
      margin-left: 10px;
      font-size: 12px;
    }
  }
  .number {
    margin: 104px auto 144px;
    text-align: center;
    font-size: 30px;
    font-weight: 800;
  }
}
</style>

<style lang="scss">
.accrualReport {
  .el-divider--horizontal {
    height: 8px;
    margin: 20px 0;
  }
  .el-divider {
    background-color: #f1f1f1;
  }
}
</style>
